@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');


@font-face {
  font-family: 'Headline';
  src: local('Headline'), url('/public/fonts/Headline.ttf') format('truetype');
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  
  background: #091c30
}

*::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: #888;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0; 
}

.flex-container {
  display: flex;
  justify-content: center;
  z-index:1;
  align-items: center;
  text-align: center;
  background:#0a1929;
  height: 100vh;
}



.item{

  z-index:2

}

.logo{
  width: 600px;
  height: 600px;
  z-index:2;
  filter: drop-shadow(0 0 0.75rem rgba(0, 7, 131, 0.71));
}

.item:nth-of-type(1){
margin-left: 33%;

  
}
.item p{
  color:white;
}


.item:nth-of-type(1) p:nth-of-type(1){
  font-size: 45px;
  font-family: 'Playfair Display', serif;
  text-shadow: 0px 2px 7px rgba(0,0,0,0.54);

}
.item:nth-of-type(1) p:nth-of-type(2){
  /* font-family: 'Courgette', cursive; */
  font-family: 'Oswald', sans-serif;
  text-shadow: 0px 2px 7px rgba(0,0,0,0.54);
  

  margin-top: -58px;
  /* padding-left: 70px; */
  /* padding-left: 118px; */
  font-size: 80px
}
.item:nth-of-type(1) p:nth-of-type(3){
  /* margin-left: 15%; */
  font-size: 20px;
  text-shadow: 0px 2px 7px rgba(0,0,0,0.54);
}

video{
position:absolute;
top:0;
left:0;
height:100vh;
width:100%;
object-fit:cover;
}


.flip-card {
  padding-top:15px;
  padding-left:15px;
  background-color: transparent;
  width: 174px;
  height: 256px;
  perspective: 1000px;
  margin-top:15px;
  
}

.flip-card-inner {
  
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {

  padding-top:50%;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #ffc963;
  color: black;
}

.flip-card-back {
  background-color: #352612;
  color: white;
  transform: rotateY(180deg);
}


.proiecte-personale-big{
  padding-left:15%;
  padding-right:15%;
  background:#0a1929;

  top:0;
}

.tehnologii-section{
  padding-left:15%;
  padding-right:15%;
  background:#0a1929;
  top:0;
  
  background-image: url("https://www.brazvan.com/files/particule.png");


  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.tehnologii-grid{
  display:grid;
  grid-template-columns: repeat(4,1fr);
  gap: 6%;

}


.tehnologii-grid2{
  display:grid;
  grid-template-columns: repeat(3,1fr);
  margin-left: 15%;
  margin-top: 5%;

}
.tehnologii-item{
  margin-top: 9%;
  border: solid 1px #007cff;
  background: #003266;
  border-radius: 5px;
  place-items: center;
 display: grid;
 justify-content: center;
 height: 237px;
 width: 185px;

 transition: transform .1s  !important; 
 
}

.tehnologii-item:hover {
  -ms-transform: scale(1.2)  !important; /* IE 9 */
  -webkit-transform: scale(1.2)  !important; /* Safari 3-8 */
  transform: scale(1.1)  !important; 
}

.tehnologii-item h4{
  color: white;
 font-size: 30px;
}



.experianta-section{
  padding-left:15%;
  padding-right:15%;
  padding-top:3%;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, #0a1929 44%);
  top:0;
}

.contact-section{
  background: black;
  padding-left:15%;
  padding-right:15%;
}

.div-cv{
  display: flex;
    justify-content: center;
}

.cv-download{
  color:white;
  display: flex;
  justify-content:center;
  padding-top: 3%;
  padding-bottom: 3%;
  text-decoration:underline;
  cursor:pointer;
  display: inline-block;
}


.cv-download:hover{
  text-underline-offset: 5px;
}


.cv-download:visited{
  color:white;
}




/* 
 li{
  
  background: url("https://www.brazvan.com/border.png") no-repeat top left;
  height:299px;
  list-style: none;
  width:200px;

}
*/
.cv-technologies{
  color: red;
}
.proiecte{
  /* background: url("https://www.shanemielke.com/img/fwa/frame-sotd-cropped.png") no-repeat top left; */
  background: url("https://www.brazvan.com/border.png") no-repeat top left;
  height:299px;
  list-style: none;
  width:200px;

}


.grid-container{
 
  display:grid;
  grid-template-columns: repeat(3,1fr);
  align-items:start; 
  align-items:center;
  /* gap: 3.5% */
  grid-column-gap: 3.5%;
}


/* .eyes-follow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 50vh;
} */
.eyes-follow .eyes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.eyes-follow .eyes .eye {
  border-radius: 50%;
  height: 40px;
  width: 40px;

  margin-top:-615px;
}
.eyes-follow .eyes .eye .pupil {
  background: red;
  border-radius: 50%;
  position: absolute;
  width: 13px;
  height: 13px;
  left: 25px;
  top: 12px;
}

#right-eye{
  margin-left: 51px
}

.hello{
  /* font-family: 'ArialicHallow' !important; */
  font-family: 'Headline' !important;
  font-size: 45px !important;
  text-transform: uppercase;
  color: #ffffffa3 !important;
}

/* .nume::before{
  content:"Hello I'm";
  font-size: 15px;
  position: absolute;
  margint-top: 10px;
} */

.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background:
  #191919
      url("https://cdn.dribbble.com/users/202592/screenshots/2129004/smoke_skull.gif") center

      no-repeat;
  z-index: 1;
}

.formular p{
  color: white;
  float: right;
  margin-right:50px;
}


textarea {
  resize: none;
  vertical-align: top;
  height:110px;
  width: 397px;
  resize:none;
display: inline-block;
background:rgb(0, 50, 102);
outline: 0;
border: solid 1px #005bbc;
border-radius: 3px;
  color: white;
}


.grid-contact-container{
  display: grid;
  grid-template-columns: repeat(2,25em);
  row-gap: 10px;
  padding-bottom: 10%;
}

.gridContactItem:nth-of-type(9){
  grid-column: 1/3;
  display: flex;
  justify-content: center;
  margin-left: 20%;
	cursor: pointer;

  
}
.gridContactItem:nth-of-type(10){
  grid-column: 1/3;
  display: flex;
  justify-content: center;
  padding-top: 35px;
  margin-left: 20%;
}

.gridContactItem:nth-of-type(2) input:nth-of-type(2) {
  margin-left: 35px;
  background:rgb(0, 50, 102);
  outline: 0;
  border: solid 1px #005bbc;
  border-radius: 3px;
}
.gridContactItem:nth-of-type(9) input {
 
  background:rgb(0, 50, 102);
  outline: 0;
  border: solid 1px #005bbc;
  border-radius: 3px;
	cursor: pointer;
 margin-top: 35px;
}
.gridContactItem:nth-of-type(4) input {
  
  background:rgb(0, 50, 102);
  outline: 0;
  border: solid 1px #005bbc;
  border-radius: 3px;
  width: 100%;
}
.gridContactItem:nth-of-type(2) input:nth-of-type(4) {
  width: 397px;
  
}

.gridContactItem:nth-of-type(2) input:nth-of-type(1) {
  background:rgb(0, 50, 102);
  outline: 0;
  border: solid 1px #005bbc;
  border-radius: 3px;
}

.gridContactItem input{
  height: 40px;
  width: 180px;
  color: white;
  padding-left: 10px;
}

::placeholder{
  padding-left: 10px;
  color: white;
}

input[type=file] {
	cursor: pointer;
  background: rgb(0, 50, 102);
  padding-top: 10px;
  border: solid 1px #005bbc;
  border-radius: 3px;
  width: 100%
	
}

.gridContactItem:nth-of-type(2) input:nth-of-type(10) {
	cursor: pointer;

}

a:visited { 
  text-decoration: none; 
  color: orange; 
 }



.text-modal{
  text-decoration: underline;
  color: orange;
  cursor: pointer
}


/* MOBIL */

@media (max-width:768px) {

  .proiecte-personale-big h2{
    display: flex;
    justify-content: center;
  }

  .tehnologii-section h2{
    display: flex;
    justify-content: center;
  }
  .experianta-section h2{
    display: flex;
    justify-content: center;
  }
  .contact-section h2{
    display: flex;
    justify-content: center;
  }

  .grid-container{
    justify-items: center;
    display:grid;
    grid-template-columns: repeat(1,1fr);
    align-items:start; 
    align-items:center;
    
  }

  .tehnologii-grid{
    display:grid;
    grid-template-columns: repeat(1,1fr);
    gap: 0%;
    justify-items: center;
  }
  
  
  .tehnologii-grid2{
    display:grid;
    grid-template-columns: repeat(1,1fr);
    margin-left: 0%;
    margin-top: 5%;
    justify-items: center;
  }

  

  .grid-contact-container{
    display: grid;
    grid-template-columns: repeat(1,1em);
    row-gap: 10px;
    padding-bottom: 10%;
  }

  .formular .fieldName{
    margin: 0;
    display: none;
    
  }

  .formular{
    /* margin-left: -30px; */
    margin-top: 30px;
  }

  .gridContactItem:nth-of-type(4) input {
    width: 100%
  
  }
  .gridContactItem:nth-of-type(8) input {
    width: 100%
  
  }

  .hero{
    overflow: hidden;
  }

  .item:nth-of-type(1){
    padding-left: 257px ;
    margin-left: 0
  }

  .item:nth-of-type(2){
    margin-left: -190px;
  }

  .contact-section{
    padding-left: 2%
  }

  textarea{
    width: 100%
  }

  .gridContactItem:nth-of-type(2) input:nth-of-type(2) {
    margin-left: 0px;
    width: 100%;
    margin-top: 10px;
  }
  .gridContactItem:nth-of-type(2) input:nth-of-type(1) {
    margin-left: 0px;
    width: 100%
  }

  @keyframes reactIconAnimation{
    0% {
      color: #61dbfb;
    }
    20% {
      color: #61dbfb;
    }
    40% {
      color: white;
    }
    60% {
      color: #61dbfb;
    }
    80% {
      color: white;
    }
    100% {
      color: #61dbfb;
    }
  
  }
  @-webkit-keyframes reactIconAnimation{
    0% {
  fill: #61dbfb;
    }
    20% {
    fill: #61dbfb;
    }
    40% {
    fill: white;
    }
    60% {
    fill: #61dbfb;
    }
    80% {
    fill: white;
    }
    100% {
    fill: #61dbfb;
    }
  
  }
  
  .reactIcon{
    animation: reactIconAnimation 5s infinite;
    -webkit-animation: reactIconAnimation 5s infinite;
  }

  @keyframes html5Animation{
    0% {
      color: #E44D25;
    }
    20% {
      color: #E44D25;
    }
    40% {
      color: white;
    }
    60% {
      color: #E44D25;
    }
    80% {
      color: white;
    }
    100% {
      color: #E44D25;
    }
  
  }
  @-webkit-keyframes html5Animation{
    0% {
      fill: #E44D25;
    }
    20% {
      fill: #E44D25;
    }
    40% {
      fill: white;
    }
    60% {
      fill: #E44D25;
    }
    80% {
      fill: white;
    }
    100% {
      fill: #E44D25;
    }
  
  }
  
  .html5Icon{
    animation: html5Animation 5s infinite;
  }

  @keyframes expressAnimation{
    0% {
      color: #8CC84B;
    }
    20% {
      color: #8CC84B;
    }
    40% {
      color: white;
    }
    60% {
      color: #8CC84B;
    }
    80% {
      color: white;
    }
    100% {
      color: #8CC84B;
    }
  
  }

  @-webkit-keyframes expressAnimation{
    0% {
      fill: #8CC84B;
    }
    20% {
      fill: #8CC84B;
    }
    40% {
      fill: white;
    }
    60% {
      fill: #8CC84B;
    }
    80% {
      fill: white;
    }
    100% {
      fill: #8CC84B;
    }
  
  }
  
  .expressIcon{
    animation: expressAnimation 5s infinite;
  }
  @keyframes pythonIconAnimation{
    0% {
      color: #FFD342;
    }
    20% {
      color: #FFD342;
    }
    40% {
      color: white;
    }
    60% {
      color: #FFD342;
    }
    80% {
      color: white;
    }
    100% {
      color: #FFD342;
    }
  
  }
  @-webkit-keyframes pythonIconAnimation{
    0% {
      fill: #FFD342;
    }
    20% {
      fill: #FFD342;
    }
    40% {
      fill: white;
    }
    60% {
      fill: #FFD342;
    }
    80% {
      fill: white;
    }
    100% {
      fill: #FFD342;
    }
  
  }
  
  .pythonIcon{
    animation: pythonIconAnimation 5s infinite;
  }


}

@keyframes modal-video {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes modal-video-inner {
	from {
		transform: translate(0, 100px);
	}

	to {
		transform: translate(0, 0);
	}
}

.modal-video {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	z-index: 1000000;
	cursor: pointer;
	opacity: 1;
	animation-timing-function: ease-out;
	animation-duration: .3s;
	animation-name: modal-video;
	-webkit-transition: opacity .3s ease-out;
	-moz-transition: opacity .3s ease-out;
	-ms-transition: opacity .3s ease-out;
	-o-transition: opacity .3s ease-out;
	transition: opacity .3s ease-out;
}

.modal-video-effect-exit {
	opacity: 0;

	& .modal-video-movie-wrap {
		-webkit-transform: translate(0, 100px);
		-moz-transform: translate(0, 100px);
		-ms-transform: translate(0, 100px);
		-o-transform: translate(0, 100px);
		transform: translate(0, 100px);
	}
}

.modal-video-body {
	max-width: 960px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	padding: 0 10px;
    display: flex;
    justify-content: center;
	box-sizing: border-box;
}

.modal-video-inner {
	display: flex;
    justify-content: center;
    align-items: center;
	width: 100%;
	height: 100%;

    @media (orientation: landscape) {
        padding: 10px 60px;
        box-sizing: border-box;
    }
}

.modal-video-movie-wrap {
	width: 100%;
	height: 0;
	position: relative;
	padding-bottom: 56.25%;
	background-color: #333;
	animation-timing-function: ease-out;
	animation-duration: .3s;
	animation-name: modal-video-inner;
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transition: -webkit-transform .3s ease-out;
	-moz-transition: -moz-transform .3s ease-out;
	-ms-transition: -ms-transform .3s ease-out;
	-o-transition: -o-transform .3s ease-out;
	transition: transform .3s ease-out;

	
}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-video-close-btn {
	position: absolute;
	z-index: 2;
	top: -45px;
	right: 0px;
	display: inline-block;
	width: 35px;
	height: 35px;
	overflow: hidden;
	border: none;
	background: transparent;

    @media (orientation: landscape) {
        top: 0;
        right: -45px;
    }

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		height: 2px;
		width: 100%;
		top: 50%;
		left: 0;
		margin-top: -1px;
		background: #fff;
		border-radius: 5px;
		margin-top: -6px;
	}
}


.reactIcon {
  color: white;
}

.reactIcon:hover {
  fill: #61dbfb;
  filter: drop-shadow( 3px 3px 20px rgba(97,219,251,0.53));
  transition: .18s;
}

.html5Icon:hover{
  fill: #E44D25;
  filter: drop-shadow( 3px 3px 20px rgba(228,77,37,0.53));
  transition: .18s;
}

.expressIcon:hover{
  fill: #8CC84B;
  filter: drop-shadow( 3px 3px 20px rgba(140,200,75,0.53));
  transition: .18s;
}

.pythonIcon:hover{
  fill: #FFD342;
  filter: drop-shadow( 3px 3px 20px rgba(255,211,66,0.53));
  transition: .18s;
}
